import React, {useRef, useEffect, useState} from 'react'
import {Sizes} from '../constants'


function AutoscrollingContent({headerRef, footerRef, containerSize, children, extraSpace = 0,  hasAudioPlayer = false}) {
	const containerRef = useRef()
	const contentRef = useRef()
	const [doScrollContent, setDoScrollContent] = useState(false)


	useEffect(() => {
		updateContentSize()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [containerSize])

	const updateContentSize = () => {
		if (!headerRef.current || !contentRef.current) {
			return
		}
		const naturalContentHeight = contentRef.current.getBoundingClientRect().height + 10
		const headerHeight = headerRef.current.getBoundingClientRect().height
		const footerHeight = footerRef.current.getBoundingClientRect().height
		// console.log(headerHeight + footerHeight)
		//console.log(containerSize)
		let contentHeight = (Math.floor(containerSize.height * Sizes.assetViewMaxHeight) - headerHeight - footerHeight - (Sizes.assetViewMarginsT + Sizes.assetViewMarginsB))
		if (hasAudioPlayer) {
			contentHeight -= Sizes.audioPlayerHeight
		}
		if (extraSpace) {
			contentHeight -= extraSpace
		}
		if (naturalContentHeight + extraSpace > contentHeight) {
			//contentHeight += extraSpace
			setDoScrollContent(true)
		} else {
			contentHeight = naturalContentHeight + extraSpace
			setDoScrollContent(false)
		}
		//console.log(containerRef.current.offsetHeight, contentHeight)
		containerRef.current.style.height = contentHeight + "px"

	}

	return (
		<div className={doScrollContent ? "scrollGradient" : null}>
		<div className={'scrollContentContainer' + (doScrollContent ? " scrolling" : "")} ref={containerRef}>
			{React.cloneElement(children, {ref:contentRef})}
		</div>
		</div>
	)
}

export default AutoscrollingContent
