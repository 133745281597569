import React from 'react'
//import Modal from 'react-modal'
import AssetDisplayView from '../components/AssetDisplayView'
import {useStore} from "../store"

import AstScene1Info from "./AstScene1Info"
import AstTeacherWelcome from "./AstTeacherWelcome"
import AstInteractiveWhiteboard from "./AstInteractiveWhiteboard"
import AstPencilcase from "./AstPencilcase"
import AstNoticeboard from "./AstNoticeboard"
import AstSchoolRules from "./AstSchoolRules"
import AstCupboard from "./AstCupboard"



function AssetViewer() {
	const currentAsset = useStore(state => state.currentAsset)
	
	// const afterOpenModal = () => {}
	let modalSize = null

	const getAssetContent = () => {
		let theContent
		switch(currentAsset) {
			case "astScene1Info":
				theContent = <AstScene1Info />
				//modalSize = AssetDisplayView.sizeClasses.medium
				break
			case "astTeacherWelcome":
				theContent = <AstTeacherWelcome />
				//modalSize = AssetDisplayView.sizeClasses.landscapeW35
				break
			case "astInteractiveWhiteboard":
				theContent = <AstInteractiveWhiteboard />
				//modalSize = AssetDisplayView.sizeClasses.landscapeW70
				break
			case "astPencilcase":
				theContent = <AstPencilcase />
				//modalSize = AssetDisplayView.sizeClasses.landscapeW70
				break
			case "astNoticeboard":
				theContent = <AstNoticeboard />
				modalSize = AssetDisplayView.sizeClasses.landscapeW60 + " max-w500"
				break
			case "astSchoolRules":
				theContent = <AstSchoolRules />
				modalSize = "max-w420"
				break
			case "astCupboard":
				theContent = <AstCupboard />
				//modalSize = AssetDisplayView.sizeClasses.landscapeW70
				break

			default:
				theContent = (
				<>
					<h2>Das ist das Asset <small className="label">40px</small></h2>
					<i className="icon icon-menu"></i>
					<i className="icon icon-arrow-right"></i>
					<i className="icon icon-mail"></i>
				</>
				)
		}
		return theContent
	}

	const assetContent = currentAsset && getAssetContent() // must be called here to have the size class in place

	return (
		<AssetDisplayView
			// onAfterOpen={afterOpenModal}
			sizeClass={modalSize}
		>
			{assetContent}
		</AssetDisplayView>
	)
}

export default AssetViewer
