import React from 'react'
//import Modal from 'react-modal'
import MyDialog from './MyDialog'
import { useStore } from "../store"
import { requestDeviceOrientation } from '../utilities'

//import AstScene1Info from "./AstScene1Info"


function DialogViewer() {
	const dialogContent = useStore(state => state.dialogContent)
	const setHasDeviceOrientation = useStore(state => state.setHasDeviceOrientation)
	const isTouchDevice = useStore(state => state.isTouchDevice)
	
	const afterCloseDialog = async () => {
		if (dialogContent === DialogViewer.dialogTypes.intro && isTouchDevice) {
		//if (true) {
			const hasDevOrientation = await requestDeviceOrientation()
			//alert("hasDevOrientation: " + hasDevOrientation)
			setHasDeviceOrientation(hasDevOrientation)
		}
	}

	const getDialogContent = () => {
		let theContent
		//console.log(dialogContent)
		switch(dialogContent) {
			case DialogViewer.dialogTypes.intro:
				if (isTouchDevice) {
					theContent = (
						<div className="dialog-content text-center" style={{maxWidth: '650px'}}>
							<h1>Hinweis zur Navigation</h1>
							<div className="columns">
								<div className="column">
								<figure className="figure">
									<img src="./assets/images/Swipe_Action.png" alt="Abbildung Finger Swipe" style={{height: '58px'}} />
									<figcaption className="figure-caption text-center">Wischen zum Bewegen und um Hotspots zu entdecken</figcaption>
								</figure>
								</div>
								<div className="column">
								<figure className="figure">
									<img src="./assets/images/Pinch_Action.png" alt="Abbildung 2 Finger Pinch" style={{height: '58px'}} />
									<figcaption className="figure-caption text-center">Auf- und Zuziehen zum Vergrößern oder Verkleinern</figcaption>
								</figure>
								</div>
							</div>
							
						</div>
						)
				} else {
					theContent = (
						<div className="dialog-content text-center" style={{maxWidth: '650px'}}>
							<h1>Hinweis zur Navigation</h1>
							<figure className="figure">
								<img src="./assets/images/Zoom_Keys.png" alt="Abbildung Plus- und Minus-Taste" style={{width: '40px'}} />
								<figcaption className="figure-caption text-center">Tastaturbefehle für Zoom<br/>(oder Mausrad)</figcaption>
							</figure>
						</div>
						)
				}
				break
			

			default:
				theContent = (
				<div className="dialog-content text-center">
					<h1>Viel Spaß!</h1>
				</div>
				)
		}
		return theContent
	}

	const theDialogContent = getDialogContent() // must be called here to have the size class in place

	return (
		<MyDialog
			onAfterClose={afterCloseDialog}
		>
			{theDialogContent}
		</MyDialog>
	)
}

DialogViewer.dialogTypes = {
	intro: "intro",
}

export default DialogViewer
