
const HotspotActions = {
	setScene: "setScene",
	showAsset: "showAsset"
}

const HostspotDistance = 50
const HostspotBaseScale = 4.5

const IconTypes = {
	image: "image",
	audio: "audio",
	text: "text",
	doubleArrowUp: "doubleArrowUp",
	image_first: "image_first",
}

const Sizes = {
	audioPlayerHeight: 52,
	assetViewMarginsH: 20,
	assetViewMarginsT: 20,
	assetViewMarginsB: 14,
	assetViewMaxHeight: .94,
	assetViewMaxWidth: .94,
	assetViewMinWidth: 220,
	audioPlayerMinWidth: 340
}

export {
	HotspotActions,
	HostspotDistance,
	HostspotBaseScale,
	IconTypes,
	Sizes
}