import React, {useRef} from 'react'
import AudioPlayer from '../components/AudioPlayer'
import AutoscrollingContent from '../components/AutoscrollingContent'

function AstSchoolRules({containerSize}) {
	const headerRef = useRef()
	const footerRef = useRef()

	return(
		<div className="panel-body">
			<section className="asset-header" ref={headerRef}>
				<h1>School rules</h1>
			</section>
			<section className="asset-content">
				<AutoscrollingContent
					headerRef={headerRef}
					footerRef={footerRef}
					containerSize={containerSize}
					hasAudioPlayer={true}
					extraSpace={15}
				>
					<ol className="large">
						<li>Treat people and things with respect.</li>
						<li>Listen to your classmates and teachers.</li>
						<li>Raise your hand to speak.</li>
						<li>Be prepared for class.</li>
						<li>Always do your best.</li>
						<li>Ask questions and help others.</li>
					</ol>
				</AutoscrollingContent>
				<div className="spacer-top" />
				<AudioPlayer url="./assets/audio/au25864010_Track_005.mp3" />
			</section>
			<section className="asset-footer" ref={footerRef}>
				<p className="reference">Textquelle: Ernst Klett Verlag GmbH<br/>
				Audioquellen: Tonmeister: Joschi Kauffmann, Stuttgart;<br/>Tonstudio: jkstudio GmbH, Stuttgart; Sprecher: Mark Borrill
				</p>
			</section>
		</div>
	)
}

export default AstSchoolRules
