import React from 'react'
import PropTypes from 'prop-types'
import {KlettSVGIcons} from './KlettSVGIcons'

const KlettIcon = props => {
	const styles = {
		svg: {
			display: 'inline-block',
			verticalAlign: 'middle',
		},
		path: {
			fill: props.color || 0x606060,
		},
	}

	const theIcon = KlettSVGIcons[props.icon]

	return (
		theIcon[0] === "<" ? 
				<span dangerouslySetInnerHTML={{__html:theIcon}} />
				:
			<svg
				style={styles.svg}
				width={`${props.size}px`}
				height={`${props.size}px`}
				viewBox="0 0 128 128"
			>
				<path
					style={styles.path}
					d={KlettSVGIcons[props.icon]}
				></path>
			</svg>
	)
}

KlettIcon.propTypes = {
	icon: PropTypes.string.isRequired,
	size: PropTypes.number,
	color: PropTypes.string,
}

KlettIcon.defaultProps = {
	size: 28,
}

export default KlettIcon
