import React, {useRef} from 'react'
import AudioPlayer from '../components/AudioPlayer'
import AdjustingImage from '../components/AdjustingImage'


function AstCupboard({containerSize}) {
	const headerRef = useRef()
	const footerRef = useRef()

	return(
		<div className="panel-body">
			<section className="asset-header" ref={headerRef}>
				<h1>My cupboard</h1>
			</section>
			<section className="asset-content">
				<AdjustingImage src="./assets/images/RO-E5JJ_Cupboard.jpg" alt="Blick in den geöffneten Schrank"
					headerRef={headerRef}
					footerRef={footerRef}
					containerSize={containerSize}
					hasAudioPlayer={true}
				/>
				<AudioPlayer url="./assets/audio/au25864010_Track_006.mp3" />
			</section>
			<section className="asset-footer" ref={footerRef}>
				<p className="reference">Bildquelle: Shutterstock (junpiiiiiiiiiii)<br/>
				Audioquellen: Tonmeister: Joschi Kauffmann, Stuttgart; Tonstudio: jkstudio GmbH, Stuttgart; Sprecher: Mark Borrill
				</p>
			</section>
		</div>
	)
}

export default AstCupboard
