import React, {useRef} from 'react'
import AudioPlayer from '../components/AudioPlayer'
import AdjustingImage from '../components/AdjustingImage'


function AstTeacherWelcome({containerSize}) {
	const headerRef = useRef()
	const footerRef = useRef()

	return(
		<div className="panel-body">
			<section className="asset-header" ref={headerRef}>
				<h1>Welcome to my classroom!</h1>
			</section>
			<section className="asset-content">
				<AdjustingImage src="./assets/images/RO-ED7G_Teacher.jpg" alt="Ein Lehrer der Schule"
					headerRef={headerRef}
					footerRef={footerRef}
					containerSize={containerSize}
					hasAudioPlayer={true}
				/>
				<AudioPlayer url="./assets/audio/au25864010_Track_001.mp3" />
			</section>
			<section className="asset-footer" ref={footerRef}>
				<p className="reference">Bildquelle: ShutterStock.com RF (Monkey Business Images)<br/>
				Audioquellen: Tonmeister: Joschi Kauffmann, Stuttgart; 
				Tonstudio: jkstudio GmbH, Stuttgart; Sprecher: Mark Borrill; Noch eine längere Angabe für die Audioquellen um viele Zeilen mit vielen Sprechern und anderen Beteiligten zu simmulieren.
				</p>
			</section>
		</div>
	)
}

export default AstTeacherWelcome
