import React, {useState, useEffect, useRef} from 'react'
import {useThree, useLoader, useFrame} from 'react-three-fiber'
import {TextureLoader, DoubleSide} from 'three'
import {Html} from '@react-three/drei'
import {useSpring, animated} from 'react-spring/three'
import PropTypes from 'prop-types'
import {HostspotBaseScale, IconTypes} from '../constants'
import iconImage from '../icons/icon_image.png'
import iconImageVisited from '../icons/icon_image_visited.png'
import iconText from '../icons/icon_text.png'
import iconTextVisited from '../icons/icon_text_visited.png'
import iconAudio from '../icons/icon_audio.png'
import iconAudioVisited from '../icons/icon_audio_visited.png'
import iconNeutral from '../icons/icon_neutral.png'
import iconNeutralVisited from '../icons/icon_neutral_visited.png'
import iconDoubleArrowUp from '../icons/icon_doubleArrowUp.png'
import iconDoubleArrowUpVisited from '../icons/icon_doubleArrowUp_visited.png'
import iconImageFirst from '../icons/icon_image_w_1.png'
import iconImageFirstVisited from '../icons/icon_image_w_1_visited.png'

function Hotspot(props) {
	const [hovered, setHovered] = useState(false)
	const [visited, setVisited] = useState(false)
	const [doPulsing, setPulsing] = useState(props.doPulsing === true)
	//const iconSrc = '/assets/icons/' + props.icon + ".png"
	//const iconSrcVisited = '/assets/icons/' + props.icon + "_visited.png"
	//const texture = new TextureLoader().load(iconSrc)
	const iconRef = useRef()

	const handleClick = () => {
		setVisited(true)
		setHovered(false)
		setPulsing(false)
		props.onClick()
	}

	const handlePointerOver = () => {
		// TODO: scale with greensock
		setHovered(true)
	}

	const handlePointerOut = () => {
		setHovered(false)
	}

	const { gl } = useThree()
	useEffect(() => void(
		gl.domElement.style.cursor = hovered ? 'pointer' : 'auto'
	// eslint-disable-next-line react-hooks/exhaustive-deps
	), [hovered])

	const getIcon = (iconType) => {
		let myIcon, myIconVisited
		switch(iconType) {
			case IconTypes.audio:
				myIcon = iconAudio
				myIconVisited = iconAudioVisited
				break

			case IconTypes.text:
				myIcon = iconText
				myIconVisited = iconTextVisited
				break

			case IconTypes.image:
				myIcon = iconImage
				myIconVisited = iconImageVisited
				break
			
			case IconTypes.doubleArrowUp:
				myIcon = iconDoubleArrowUp
				myIconVisited = iconDoubleArrowUpVisited
				break

			case IconTypes.image_first:
				myIcon = iconImageFirst
				myIconVisited = iconImageFirstVisited
				break

			default:
				myIcon = iconNeutral
				myIconVisited = iconNeutralVisited
				break
		}

		return [myIcon, myIconVisited]
	}

	const [theIcon, theIconVisited] = getIcon(props.icon)
	const texture = useLoader(TextureLoader, theIcon)
	const textureVisited = useLoader(TextureLoader, theIconVisited)


	const overProps = useSpring({
		scale: hovered ? [HostspotBaseScale * 1.25, HostspotBaseScale * 1.25, 1] : [HostspotBaseScale, HostspotBaseScale, 1],
		config: { mass: 5, tension: 350, friction: 40, duration: 300 }
	})

	const scaleDelta = (HostspotBaseScale * 1.3 / 2) - HostspotBaseScale

	useFrame((state) => {
		if (!doPulsing || hovered)
			return
		if (!iconRef.current)
			return

		const s = (HostspotBaseScale * 1.15) + (Math.sin(state.clock.getElapsedTime() * 4) * scaleDelta)
		iconRef.current.scale.x = s
		iconRef.current.scale.y = s
	})

	// const rotProps = useSpring({
	// 	to: async(next, cancel) => {
	// 		await next({rotation: [-1, Math.PI, 0]})
	// 		await next({rotation: [0, Math.PI, 0]})
	// 	},
	// 	from: {rotation: [0, Math.PI, 0]},
	// 	config: { mass: 5, tension: 350, friction: 40, duration: 300 },
	// })

	//console.log(rotProps)
	return (
		<animated.sprite
			scale={overProps.scale}
			ref={iconRef}
			position={props.position}
			rotation={[0, Math.PI, 0]}
			//rotation={rotProps}
			onClick={handleClick}
			onPointerOver={handlePointerOver}
			onPointerOut={handlePointerOut}
		>
			<spriteMaterial attach="material" map={visited ? textureVisited : texture} color={props.color} transparent opacity={1} side={DoubleSide} />
			{props.infoText &&
			<Html scaleFactor={70} className="hs-infotext-container" zIndexRange={[15, 0]} center={true}>
				<div className="hs-infotext"><p>{props.infoText}</p></div>
			</Html> }
		</animated.sprite>
	)

}

Hotspot.propTypes = {
	position: PropTypes.arrayOf(PropTypes.number),
	onClick: PropTypes.func,
	color: PropTypes.any,
	icon: PropTypes.string,
	infoText: PropTypes.string
}

export default Hotspot
