import React, {useRef, useEffect, useState, useCallback} from 'react'
import {Sizes} from '../constants'
import debounce from 'lodash.debounce'


function AdjustingImage({src, alt, headerRef, footerRef, containerSize, extraSpace = 0,  hasAudioPlayer = false}) {
	const [imgHidden, setImgHidden] = useState(true)
	const [naturalImageHeight, setNaturalImageHeight] = useState(0)
	const [naturalImageWidth, setNaturalImageWidth] = useState(0)
	const imgRef = useRef()

	function onImgLoad() {
		//console.log(`Image width ${imgRef.current.naturalWidth}, height ${imgRef.current.naturalHeight}`)
		setNaturalImageWidth(imgRef.current.naturalWidth)
		setNaturalImageHeight(imgRef.current.naturalHeight)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const dbSetImageSize = useCallback(
		debounce(() => setImageSize(), 200),
		[containerSize, naturalImageHeight]
	)

	useEffect(() => {
		dbSetImageSize()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [containerSize, naturalImageHeight])

	const setImageSize = () => {
		if (isNaN(naturalImageHeight) || naturalImageHeight === 0) {
			return
		}
		if (!headerRef.current && ! footerRef.current) {
			return
		}
		//console.log("container", containerSize)
		const headerHeight = headerRef.current.getBoundingClientRect().height
		//const headerWidth = headerRef.current.getBoundingClientRect().width
		const footerHeight = footerRef.current.getBoundingClientRect().height
		// console.log(headerHeight + footerHeight)
		//console.log(containerSize)
		let imgHeight = (Math.floor(containerSize.height * Sizes.assetViewMaxHeight) - headerHeight - footerHeight - (Sizes.assetViewMarginsT + Sizes.assetViewMarginsB))
		if (hasAudioPlayer) {
			imgHeight -= Sizes.audioPlayerHeight
		}
		if (extraSpace) {
			imgHeight -= extraSpace
		}
		const ratio = naturalImageWidth / naturalImageHeight
		const calcWidth = imgHeight * ratio
		const maxContentWidth = (containerSize.width * Sizes.assetViewMaxWidth) - (2 * Sizes.assetViewMarginsH)
		//console.log(`calcWidth: ${calcWidth}, available width: ${containerSize.width}`)
		
		if (calcWidth > maxContentWidth) {
		//if (calcWidth > headerWidth) {
			//imgRef.current.style.width = calcWidth + "px"
			imgHeight = maxContentWidth / ratio
		}

		imgRef.current.style.height = imgHeight + "px"

		let minWidth = Sizes.assetViewMinWidth
		if (hasAudioPlayer && maxContentWidth > Sizes.audioPlayerMinWidth) {
			minWidth = Sizes.audioPlayerMinWidth
		}
		
		const newWidth = Math.max(imgHeight * ratio, minWidth)
		const oldWidth = footerRef.current.getBoundingClientRect().width
		footerRef.current.style.width = newWidth + "px"
		if (newWidth < oldWidth) {
			dbSetImageSize() // recalc to get correction in height
		}

		setImgHidden(false)
	}

	return (
		<img
			src={src}
			alt={alt}
			className={"img-responsive" + (imgHidden ? ' d-none' : '')}
			ref={imgRef}
			onLoad={onImgLoad}
		/>
	)
}

export default AdjustingImage
