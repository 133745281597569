import React from 'react'
import AudioPlayer from '../components/AudioPlayer'


function AstNoticeboard() {
	return(
		<div className="panel-body">
			{/* <h1>Welcome to my classroom!</h1> */}
			{/* <img src="./assets/images/RO-ED7G_Teacher.jpg" className="img-responsive" alt="Ein Lehrer der Schule" /> */}
			<div className="spacer-top" />
			<AudioPlayer url="./assets/audio/au25864010_Track_004.mp3" />
			<p className="reference">Audioquellen: Tonmeister: Joschi Kauffmann, Stuttgart;<br/>Tonstudio: jkstudio GmbH, Stuttgart; Sprecher: Mark Borrill
			</p>
		</div>
	)
}

export default AstNoticeboard
