import create from "zustand"
//import {HotspotActions, IconTypes} from './constants'
import {isTouchDevice} from './utilities'



const useStore = create(set => ({
	currentScene: "scene1", // scene to start with
	currentAsset: null,
	setCurrentAsset: (assetId) => {
		set({currentAsset: assetId})
	},
	assetModalOpen: false,
	setAssetModalOpen: (isOpen) => {
		set({assetModalOpen: isOpen})
	},
	dialogOpen: false,
	setDialogOpen: (isOpen) => {
		set({dialogOpen: isOpen})
	},
	dialogContent: null, // content for the dialog
	setDialogContent: (contentId) => {
		set({dialogContent: contentId})
	},
	isFullscreen: false,
	setIsFullscreen: (isFs) => {
		set({isFullscreen: isFs})
	},
	isTouchDevice: isTouchDevice(),
	hasDeviceOrientation: false,
	setHasDeviceOrientation: (hasDevOr) => {
		set({hasDeviceOrientation: hasDevOr})
	},
	setScene: (sceneId) => {
		set({currentScene: sceneId})
	}
}))

export { useStore }
