export const KlettSVGIcons = {
	FS_EXPAND: 'M58.5,71.9a4.9,4.9,0,0,1,1.4,3.3,4.8,4.8,0,0,1-1.7,3.6L20.1,115.1l32.2-.4a6,6,0,0,1,3.8,1.2,4,4,0,0,1,1.4,3.1,4.3,4.3,0,0,1-1.4,3.2,5.6,5.6,0,0,1-3.6,1.2H9.8A5,5,0,0,1,6.3,122a5.8,5.8,0,0,1-1.5-3.6L4,75.7a5.1,5.1,0,0,1,1.2-3.6,4.5,4.5,0,0,1,3.3-1.4,3.7,3.7,0,0,1,3,1.5,5.4,5.4,0,0,1,1.2,3.6l.4,33,38.8-37a4.8,4.8,0,0,1,3.3-1.3A4.9,4.9,0,0,1,58.5,71.9Zm65.9-12.2a4.5,4.5,0,0,1-3.2,1.4,4,4,0,0,1-3.1-1.4,6,6,0,0,1-1.2-3.8L116.5,23,77.6,60a4.2,4.2,0,0,1-3.2,1.5A4.8,4.8,0,0,1,71,60a4.3,4.3,0,0,1-1.5-3.3,4.8,4.8,0,0,1,1.7-3.6l38.2-36.4L77.3,17a5.1,5.1,0,0,1-3.7-1.1,3.7,3.7,0,0,1-1.5-3.1,3.9,3.9,0,0,1,1.4-3.2,5.3,5.3,0,0,1,3.6-1.3h42.6a4.9,4.9,0,0,1,3.6,1.5,5,5,0,0,1,1.5,3.7l.8,42.6A5.6,5.6,0,0,1,124.4,59.7Z M55.3,61.4A4.8,4.8,0,0,1,52,60L13.2,23.1l-.4,33a5.6,5.6,0,0,1-1.2,3.6,3.6,3.6,0,0,1-3,1.5,4.5,4.5,0,0,1-3.3-1.4,5.7,5.7,0,0,1-1.2-3.7l.8-42.6A5.8,5.8,0,0,1,6.4,9.9,5.2,5.2,0,0,1,10,8.5H52.6a5.2,5.2,0,0,1,3.6,1.2,4,4,0,0,1,1.4,3.2A3.7,3.7,0,0,1,56.2,16a5.8,5.8,0,0,1-3.8,1.2l-32.2-.4L58.4,53.1A4.6,4.6,0,0,1,60,56.7a4.8,4.8,0,0,1-4.7,4.7Zm70.4,14.4-.8,42.6a5,5,0,0,1-1.5,3.7,4.5,4.5,0,0,1-3.5,1.4H77.2a5.6,5.6,0,0,1-3.6-1.2,4.3,4.3,0,0,1-1.4-3.2,3.7,3.7,0,0,1,1.5-3.1,5.1,5.1,0,0,1,3.7-1.1l32.1.3L71.3,78.8a4.7,4.7,0,0,1-1.6-3.7,4.2,4.2,0,0,1,1.5-3.2,4.6,4.6,0,0,1,3.3-1.5,4.2,4.2,0,0,1,3.2,1.5l38.9,37L117,76a5.8,5.8,0,0,1,1.2-3.8,3.8,3.8,0,0,1,3.1-1.4,4,4,0,0,1,3.2,1.4A4.7,4.7,0,0,1,125.7,75.8Z',
	
	FS_CONTRACT: 'M58,70.9a4.5,4.5,0,0,1,1.5,3.5l.8,42a5.1,5.1,0,0,1-1.2,3.5,4,4,0,0,1-3.2,1.4,3.9,3.9,0,0,1-3-1.4,5.6,5.6,0,0,1-1.2-3.6l-.4-32.5L12.9,120.6A4.5,4.5,0,0,1,9.6,122a4.1,4.1,0,0,1-3.2-1.4A4.3,4.3,0,0,1,5,117.3a4.7,4.7,0,0,1,1.6-3.5L44.5,77.6,12.7,78A5.7,5.7,0,0,1,9,76.8a3.7,3.7,0,0,1-1.5-3A3.7,3.7,0,0,1,9,70.7a4.8,4.8,0,0,1,3.6-1.3H54.5A4.5,4.5,0,0,1,58,70.9ZM121.2,17,83.1,53.3l31.8-.4a5.6,5.6,0,0,1,3.6,1.2,3.7,3.7,0,0,1,1.5,3,4.1,4.1,0,0,1-1.5,3.2,5.1,5.1,0,0,1-3.5,1.2H73a4.8,4.8,0,0,1-4.9-5l-.8-41.9A4.4,4.4,0,0,1,68.5,11a3.9,3.9,0,0,1,3.1-1.5,3.7,3.7,0,0,1,3,1.5,5.6,5.6,0,0,1,1.2,3.6l.3,32.5,38.8-36.9A3.9,3.9,0,0,1,118,8.8a4.9,4.9,0,0,1,3.3,1.4,4.2,4.2,0,0,1,1.5,3.2A5.4,5.4,0,0,1,121.2,17Z M54.7,61.5h-42a4.8,4.8,0,0,1-3.6-1.3,3.9,3.9,0,0,1-1.5-3.1,3.4,3.4,0,0,1,1.5-3,5.7,5.7,0,0,1,3.7-1.2l31.8.4L6.7,17.1a4.9,4.9,0,0,1-1.6-3.5,4.5,4.5,0,0,1,1.4-3.3A4.2,4.2,0,0,1,9.7,8.8,4.6,4.6,0,0,1,13,10.3L51.5,47.1l.3-32.5A5.6,5.6,0,0,1,53,11a3.6,3.6,0,0,1,3-1.4A3.9,3.9,0,0,1,59.2,11a5.1,5.1,0,0,1,1.2,3.5l-.8,42A4.8,4.8,0,0,1,58.2,60,4.7,4.7,0,0,1,54.7,61.5Zm68.2,56a4.2,4.2,0,0,1-1.5,3.2,4.9,4.9,0,0,1-3.3,1.4,4.3,4.3,0,0,1-3.1-1.4L76.3,83.8l-.4,32.5a5.6,5.6,0,0,1-1.2,3.6,3.7,3.7,0,0,1-3,1.5,3.9,3.9,0,0,1-3.1-1.5,4.4,4.4,0,0,1-1.2-3.6l.8-42a5.2,5.2,0,0,1,1.4-3.5,4.8,4.8,0,0,1,3.5-1.4h42a5.6,5.6,0,0,1,3.6,1.2,4.3,4.3,0,0,1,1.4,3.2,3.7,3.7,0,0,1-1.5,3,5,5,0,0,1-3.6,1.1l-31.8-.4,38.1,36.4A5,5,0,0,1,122.9,117.5Z',

	/*CLOSE: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24"><g id="ic_close" clip-path="url(#clip-ic_close)"><g id="ic_close_circle" transform="translate(3 3)"><g id="Gruppe_1377" data-name="Gruppe 1377" transform="translate(-6045 -575)">	<g id="Ellipse_136" data-name="Ellipse 136" transform="translate(6081 587) rotate(90)" fill="none" stroke="#a6a8aa" stroke-width="2"><circle cx="12" cy="12" r="12" stroke="none"/><circle cx="12" cy="12" r="11" fill="none"/>	</g><g id="Gruppe_1376" data-name="Gruppe 1376" transform="translate(0.5 0.5)"><path id="Pfad_1234" data-name="Pfad 1234" d="M25.61,29.16l-4.5,4.378-4.5-4.378" transform="translate(6089.61 632.038) rotate(180)" fill="none" stroke="#a6a8aa" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.4"/><path id="Pfad_1308" data-name="Pfad 1308" d="M25.61,29.16l-4.5,4.378-4.5-4.378" transform="translate(6047.391 565.34)" fill="none" stroke="#a6a8aa" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.4"/></g></g> <g id="Icon_Frame_small" data-name="Icon Frame small"><path id="Pfad_1289" data-name="Pfad 1289" d="M0,0H48V-48H0Z" transform="translate(0 48)" fill="#fff" opacity="0"/></g></g></g></svg>',*/

	CLOSE_OLD: 'M1.3,3.9,61.1,61.4l.8.3A1,1,0,0,0,63,60.6a1.1,1.1,0,0,0-.3-.8L2.9,2.3,2.1,2A1.1,1.1,0,0,0,1,3.1,1.1,1.1,0,0,0,1.3,3.9Z M61.1,2.3,1.3,59.8a1.1,1.1,0,0,0-.3.8,1,1,0,0,0,1.1,1.1l.8-.3L62.7,3.9a1.1,1.1,0,0,0,.3-.8A1.1,1.1,0,0,0,61.9,2Z',

	INFO: 'M90,102.4v11.1H46.9V102.4l11.7-1.9V58.1L46.9,56.7V45.6a178.2,178.2,0,0,1,23.8-1.4l8.6.2v56.1ZM61.4,36.3A11.8,11.8,0,0,1,57,32.1a10.1,10.1,0,0,1-1.6-5.6A11.1,11.1,0,0,1,57,20.6a10.9,10.9,0,0,1,4.3-4.2,11.7,11.7,0,0,1,6-1.6,10.8,10.8,0,0,1,5.8,1.6,11.3,11.3,0,0,1,4.2,4.2,12.1,12.1,0,0,1,1.6,5.9A11,11,0,0,1,77.3,32,13.3,13.3,0,0,1,73,36.2a10.2,10.2,0,0,1-5.7,1.6A12,12,0,0,1,61.4,36.3Z'

}
