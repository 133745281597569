import React from 'react'
import { useStore } from "../store"
import KlettIcon from './KlettIcon'
import {ReactComponent as IconFSExpand} from '../icons/icon_fullscreen_expand_v2.svg';
import {ReactComponent as IconFSContract} from '../icons/icon_fullscreen_contract_v2.svg';
//import {ReactComponent as IconInfo} from '../icons/icon_i.svg';
import scenes from "../scenes"

function MainToolbar() {
	const isFullscreen = useStore(state => state.isFullscreen)
	const setIsFullscreen = useStore(state => state.setIsFullscreen)
	const switchFs = () => setIsFullscreen(!isFullscreen)

	const setCurrentAsset = useStore(state => state.setCurrentAsset)
	const setAssetModalOpen = useStore(state => state.setAssetModalOpen)
	const currentScene = useStore(state => state.currentScene)
	const curInfo = scenes[currentScene].sceneInfo

	const handleInfoClick = () => {
		setCurrentAsset(curInfo)
		setAssetModalOpen(true)
	}

	return(
		<div className="main-toolbar">
			<button className="btn ui-btn" onClick={handleInfoClick}><KlettIcon icon='INFO'/></button>
			<button className="btn ui-btn" onClick={switchFs}>{isFullscreen ? <IconFSContract /> : <IconFSExpand />}</button>
		</div>
	)
}

export default MainToolbar
