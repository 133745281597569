import {HotspotActions, IconTypes} from './constants'

const scenes = {
	"scene1": {
		panoramaSrc: './assets/panoramas/vi07864010_360tour_panorama_classroom1.jpg',
		panoramaRotation: [0, -.8, 0], // 90 degrees
		sceneInfo: 'astScene1Info',
		hotspots: [
			{
				position: [4.35181494156416,-2.6167978121471918,-49.741472394016654],
				icon: IconTypes.image_first,
				action: HotspotActions.showAsset,
				target: "astTeacherWelcome",
				doPulsing: true,
				//infoText: "Hier starten"
			},
			{
				position: [39.37653593734814,1.7449748351250485,-30.764321549475312],
				icon: IconTypes.image,
				action: HotspotActions.showAsset,
				target: "astInteractiveWhiteboard"
			},
			{
				position: [33.32921811928047,-37.157241273869715,-2.915928749619858],
				icon: IconTypes.image,
				action: HotspotActions.showAsset,
				target: "astPencilcase"
			},
			{
				position: [-28.661351454176888,1.7449748351250485,40.932651949817924],
				icon: IconTypes.audio,
				action: HotspotActions.showAsset,
				target: "astNoticeboard"
			},
			{
				position: [38.156470636888486,4.357787137382909,32.01708043843985],
				icon: IconTypes.text,
				action: HotspotActions.showAsset,
				target: "astSchoolRules"
			},
			{
				position: [-4.304135546388549,-7.821723252011544,49.19649441339552],
				icon: IconTypes.image,
				action: HotspotActions.showAsset,
				target: "astCupboard"
			},

		]
	},

}

export default scenes;
