import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import {ReactComponent as CloseIcon} from '../icons/icon_close.svg'
import { useStore } from "../store"
import ContainerDimensions from 'react-container-dimensions'
//import {getViewPortSize} from '../utilities'


function AssetDisplayView(props) {
	Modal.setAppElement('#root')

	const assetModalOpen = useStore(state => state.assetModalOpen)
	const setAssetModalOpen = useStore(state => state.setAssetModalOpen)
	const closeModal = () => setAssetModalOpen(false)

	let classes = 'modal' + (props.sizeClass ? (' ' + props.sizeClass) : '')

	return (
		<ContainerDimensions>
			{({height, width}) => {
				//console.log(width, height)
				return(
				<Modal {...props}
					className={classes}
					overlayClassName='modal-overlay'
					isOpen={assetModalOpen}
					closeTimeoutMS={400}
					onRequestClose={closeModal}
				>
					<button className="btn icon-btn icon-close" onClick={closeModal}><CloseIcon style={{width: '24px', height: '24px', display: 'inline-block', verticalAlign: 'middle', margin: '-50% 0 0 -50%'}} /></button>
					<div className="panel">
						{props.children && React.cloneElement(props.children, {containerSize: {height, width}})}
					</div>
				</Modal>)
			}}
		</ContainerDimensions>
	)
}


AssetDisplayView.sizeClasses = {
	small: "small",
	medium: "medium",
	large: "large",
	landscapeW35: "landscape-w35",
	landscapeW40: "landscape-w40",
	landscapeW45: "landscape-w45",
	landscapeW50: "landscape-w50",
	landscapeW55: "landscape-w55",
	landscapeW60: "landscape-w60",
	landscapeW65: "landscape-w65",
	landscapeW70: "landscape-w70",
	landscapeW75: "landscape-w75",
	landscapeW80: "landscape-w80"
}

AssetDisplayView.propTypes = {
	sizeClass: PropTypes.string,
	children: PropTypes.any
}
export default AssetDisplayView
