import React, {useRef} from 'react'
//import AdjustingImage from '../components/AdjustingImage'


function AstScene1Info({availableSize}) {
	const headerRef = useRef()
	const footerRef = useRef()

	return(
		<div className="panel-body">
			<section className="asset-header" ref={headerRef}>
				<h1>Quellen</h1>
			</section>
			<section className="asset-content">
				{/* <AdjustingImage src="./assets/images/vi07864010_360tour_panorama_classroom1_splash.jpg" alt="Classroom"
					headerRef={headerRef}
					footerRef={footerRef}
					availableSize={availableSize}
					hasAudioPlayer={false}
				/> */}
				<p>
					Panoramabild: Fosseway Films Ltd. (Jesùs Santaeularia)<br/>
					Programmierung: Master Kitchen, Ludwigsburg<br/>
					© Ernst Klett Verlag GmbH, Stuttgart 2021
				</p>
			</section>
			<section className="asset-footer" ref={footerRef}>
				{/* <p className="reference">Quellen<br/>
			Panoramabild: Fosseway Films (Jesùs Santaeularia)</p> */}
			</section>
		</div>
	)
}

export default AstScene1Info
