import React, {useEffect} from 'react'
import VRViewer from './components/VRViewer'
import MainToolbar from './components/MainToolbar'
import AssetViewer from './assets/AssetViewer'
import DialogViewer from './components/DialogViewer'
import { useStore } from "./store"
//import { ResizeObserver } from '@juggle/resize-observer'
import MyFullscreen from "./components/MyFullscreen"
//import {requestDeviceOrientation} from './utilities'
import './spectre.scss'
import './App.scss'

function App() {
	const isFullscreen = useStore(state => state.isFullscreen)
	const setIsFullscreen = useStore(state => state.setIsFullscreen)
	const setDialogOpen = useStore(state => state.setDialogOpen)
	//const dialogContent = useStore(state => state.dialogContent)
	const setDialogContent = useStore(state => state.setDialogContent)

	useEffect(() => {
		//requestDeviceOrientation()
		setDialogContent(DialogViewer.dialogTypes.intro)
		//setTimeout(() => setDialogOpen(true), 200)
		setDialogOpen(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// useEffect(() => {
	// 	(async () => {
	// 		if ('ResizeObserver' in window === false) {
	// 			// Loads polyfill asynchronously, only if required.
	// 			console.log("Loading ResizeObserver polyfill.")
	// 			const module = await import('@juggle/resize-observer')
	// 			window.ResizeObserver = module.ResizeObserver
	// 		} else {
	// 			console.log("Not using ResizeObserver polyfill.")
	// 		}
	// 	})()
	// }, [])
	

	return (
		<MyFullscreen enabled={isFullscreen} onChange={setIsFullscreen}>
			<div className="App">
				<VRViewer />
				<MainToolbar />
				<AssetViewer />
				<DialogViewer />
			</div>
		</MyFullscreen>
	)
}

export default App
