import React from 'react'
import ReactDOM from 'react-dom'
import './basics.css'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'

// Make sure the ResizeObserver polyfill is loaded before starting the app!

// const startApp = () => {
// 	ReactDOM.render(
// 		<React.StrictMode>
// 			<App />
// 		</React.StrictMode>,
// 		document.getElementById('root')
// 	)
// }

// (async () => {
// 	if ('ResizeObserver' in window === false) {
// 		// Loads polyfill asynchronously, only if required.
// 		//console.log("Loading ResizeObserver polyfill.")
// 		const module = await import('@juggle/resize-observer')
// 		window.ResizeObserver = module.ResizeObserver
// 		startApp()
// 	} else {
// 		//console.log("Not using ResizeObserver polyfill.")
// 		startApp()
// 	}
// })()


ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
